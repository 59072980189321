import firebase from "firebase/app";
import { getApiDomain, getPublicApiDomain } from "../utils/domainUtils";
import "whatwg-fetch";
import "firebase/auth";

function handleErrorsAndGetJson(response) {
  if (!response.ok) {
    throw Error(response.status + " " + response.statusText);
  }
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    return response.json();
  }
  return response.body;
}

const authenticatedRequest = (method, url, body) => {
  if (!firebase.auth().currentUser) {
    // throw new Error("Not authenticated. Make sure you're signed in!");
  }

  // Get the Firebase auth token to authenticate the request
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(function (token) {
      var request = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        ...(method === "POST" && { body: JSON.stringify(body) }),
      };

      return window.fetch(url, request).then(handleErrorsAndGetJson);
    });
};

const publicRequest = (method, url, body) => {
  var request = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    ...(method === "POST" && { body: JSON.stringify(body) }),
  };
  return window.fetch(url, request).then(handleErrorsAndGetJson);
};

export const subscribe = (data) =>
  authenticatedRequest("POST", `${getApiDomain()}/subscribe`, data);

export const unsubscribe = (data) =>
  authenticatedRequest("POST", `${getApiDomain()}/unsubscribe`, data);

export const getUserInfo = () =>
  authenticatedRequest("GET", `${getApiDomain()}/user-info`);

export const getSlots = () =>
  publicRequest("GET", `${getPublicApiDomain()}/slots`);
