import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  Link,
  Spinner,
} from "@chakra-ui/react";
import { getPasswordLessSignInLink } from "../utils/domainUtils";
import firebase from "firebase/app";
import Preferences from "./Preferences";
import { IoMailOutline } from "react-icons/io5";
require("firebase/auth");

const STATUSES = {
  INITIAL: "INITIAL",
  SUBMITTING: "SUBMITTING",
  LOGIN_PENDING: "LOGIN_PENDING",
  SENT: "SENT",
  SUCCESS: "SUCCESS",
  ERROR_SENDING: "ERROR_SENDING",
  ERROR_VERIFYING: "ERROR_VERIFYINGx",
};

var actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: getPasswordLessSignInLink(),
  // This must be true.
  handleCodeInApp: true,
  //   iOS: {
  //     bundleId: "com.example.ios"
  //   },
  //   android: {
  //     packageName: "com.example.android",
  //     installApp: true,
  //     minimumVersion: "12"
  //   },
  // dynamicLinkDomain: "globalentrysentry.com"
};
const SignIn = ({ slots, showLogin, setShowLogin }) => {
  const [emailValue, setEmailValue] = useState("");

  const isProbablyAlreadySignedIn =
    localStorage.getItem("PREVIOUSLY_SIGNED_IN") || firebase.auth().currentUser;
  const initialState = isProbablyAlreadySignedIn
    ? STATUSES.LOGIN_PENDING
    : STATUSES.INITIAL;

  const [status, setStatus] = useState(initialState);

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    if (
      window.location.search.includes("oobCode") &&
      firebase.auth().isSignInWithEmailLink(window.location.href)
    ) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          setStatus(STATUSES.SUCCESS);
          // onSuccess();
          window.location = getPasswordLessSignInLink();
        })
        .catch(function (error) {
          setStatus(STATUSES.ERROR_VERIFYING);

          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }

    firebase.auth().onAuthStateChanged(function (user) {
      if (user && status !== STATUSES.SUCCESS) {
        setStatus(STATUSES.SUCCESS);
        //   getUserInfo()
        //     .then((res) => res.json())
        //     .then((data) => {
        //       setUserInfo(data);
        //     });
      } else {
        setStatus(STATUSES.INITIAL);

        // No user is signed in.
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus(STATUSES.SUBMITTING);

    firebase
      .auth()
      .sendSignInLinkToEmail(emailValue, actionCodeSettings)
      .then(function () {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", emailValue);
        setStatus(STATUSES.SENT);
      })
      .catch(function (error) {
        setStatus(STATUSES.ERROR_SENDING);

        // Some error occurred, you can inspect the code: error.code
      });
  };

  switch (status) {
    case STATUSES.INITIAL:
    case STATUSES.SUBMITTING:
      return (
        <Flex
          textAlign="center"
          minHeight="300px"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl>
            <h1
              style={{
                fontSize: "26pt",
                marginBottom: "20px",
                fontWeight: 600,
              }}
            >
              {showLogin
                ? "Welcome back, please sign in"
                : "Get free email alerts for MA vaccine appointments"}
            </h1>
            {!showLogin && (
              <h2 style={{ marginBottom: "20px" }}>
                (Unofficial. Volunteer created. Not affiliated with or endorsed
                by any government or health services entities.)
              </h2>
            )}
            <Flex w="100%" justifyContent="center">
              <Box>
                <Input
                  value={emailValue}
                  placeholder="Email address"
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                  }}
                  maxWidth="300px"
                  backgroundColor="white"
                />
              </Box>
              <Box pl="3px">
                <Button
                  onClick={(e) => handleSubmit(e)}
                  variant="solid"
                  colorScheme="twitter"
                  disabled={
                    !emailValue.length ||
                    !emailValue.includes("@") ||
                    !emailValue.includes(".")
                  }
                  // size="large"
                  isLoading={status === STATUSES.SUBMITTING}
                >
                  {showLogin ? "Sign in" : "Sign up"}
                </Button>
              </Box>
            </Flex>
            <FormHelperText>
              We don't store any passwords. We'll email you a magic link to sign
              in.
            </FormHelperText>
            <FormHelperText>
              Unsubscribe any time. We'll only send vaccine appointment alerts
              and critical account info. We will never spam you with marketing
              junk or share your email address with anyone.{" "}
              <Link textDecoration="underline" href="#faq">
                FAQ
              </Link>
            </FormHelperText>
          </FormControl>
        </Flex>
      );
    case STATUSES.SENT:
      return (
        <div style={{ textAlign: "center" }}>
          <Flex
            justifyContent="center"
            minHeight="300px"
            alignItems="center"
            direction="column"
          >
            <IoMailOutline size="35px" style={{ marginRight: "5px" }} />
            <p style={{ fontSize: "20pt", fontWeight: 500 }}>
              Thanks, now check your email
            </p>
            <p style={{ fontSize: "16pt" }}>
              We sent a sign in link to {emailValue}. Click the link to finish
              signing in.
            </p>
          </Flex>
          {/* <Icon type="mail" theme="twoTone" style={{ fontSize: "32px" }} /> */}
        </div>
      );

    case STATUSES.SUCCESS:
      return (
        <Preferences
          slots={slots}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
        />
      );

    case STATUSES.ERROR_SENDING:
      return (
        <>
          <h2>We're having trouble sending your email right now.</h2>
          <h3>Please double check that it's a valid email address.</h3>
          <h3>If this issue persists, contact us and let us know.</h3>
        </>
      );
    case STATUSES.ERROR_VERIFYING:
      return (
        <h2>
          We're having trouble signing you in. Please make sure you've clicked
          the most recent sign in email that you've received or reload the page
          and try again.
        </h2>
      );
    case STATUSES.LOGIN_PENDING:
      return (
        <Flex
          w="100%"
          minHeight="300px"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Flex>
      );
    default:
      return null;
  }
};

export default SignIn;
