import React, { useCallback, useEffect, useMemo, useState } from "react";
import firebase from "firebase/app";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Link,
  Skeleton,
  Spinner,
  toast,
  useCallbackRef,
  useToast,
} from "@chakra-ui/react";
import "firebase/auth";
import {
  IoCheckmarkCircle,
  IoNotifications,
  IoNotificationsOff,
} from "react-icons/io5";
import { getUserInfo, subscribe, unsubscribe } from "../utils/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Switch } from "react-router";

const animatedComponents = makeAnimated();

const Preferences = ({ slots, showLogin, setShowLogin }) => {
  const siteOptions = useMemo(() => {
    if (slots) {
      return Object.keys(slots)
        .sort((a, b) => {
          if (a > b) {
            return 1;
          } else {
            return -1;
          }
        })
        .map((slotName) => ({
          value: slotName,
          label: slotName,
        }));
    } else {
      return [];
    }
  }, [slots]);
  const toast = useToast();

  const email = firebase.auth().currentUser.email;
  const [userInfo, setUserInfo] = useState(null);
  const [sites, setSites] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const isUpdating = userInfo && userInfo.expirationDate;
  const fetchAndSetUserInfo = useCallback(() => {
    getUserInfo()
      .then((data) => {
        setUserInfo(data);
        setIsEnabled(data.isEnabled);
        setSites(data.sites || []);
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.message.includes("404")) {
          setUserInfo({});
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      fetchAndSetUserInfo();
    }
  }, []);
  const handleSubscribe = () => {
    if (sites && sites.length) {
      setIsLoading(true);
      subscribe({ sites })
        .then(() => {
          fetchAndSetUserInfo();
          toast.closeAll();
          toast({
            title: isUpdating ? "Subscription updated!" : "You're subscribed.",
            description:
              "You'll receive email alerts for the next 7 days. Return any time to refresh your subscription",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        })
        .catch((err) => {
          toast.closeAll();
          toast({
            title: "Error subscribing",
            description:
              "We're having trouble right now. Please try again later.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        });
    } else {
      toast.closeAll();

      toast({
        title: "Select at least one vaccine site to watch",
        description: "Double check your input and try again",
        status: "warning",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleUnsubscribe = () => {
    unsubscribe().then(() => {
      setIsLoading(true);
      fetchAndSetUserInfo();
      toast({
        title: "You're unsubscribed",
        description: "You won't receive any more alerts",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    });
  };

  if ((firebase.auth().currentUser && !userInfo) || !slots) {
    return (
      <Flex w="100%" height="300px" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box>
      <h2 style={{ marginBottom: "20px", marginTop: "20px", fontSize: "18pt" }}>
        {isUpdating
          ? "Update your alert settings"
          : "Sign up for free Massachusetts vaccine alerts"}
      </h2>
      <Flex margin="10px 0">
        <Box>
          <IoCheckmarkCircle size="20px" />
        </Box>
        <Box>
          <span>Signed in as {email ? email : "{Email not found}"}</span>
        </Box>
      </Flex>
      <FormControl textAlign="left">
        <FormLabel as="legend">
          Vaccination sites to watch (choose as many as you want)
        </FormLabel>
        {slots && (
          <Select
            value={sites.map((site) => ({ value: site, label: site }))}
            closeMenuOnSelect={true}
            onChange={(values) => {
              setSites(values.map((item) => item.value));
            }}
            components={animatedComponents}
            //   defaultValue={[colourOptions[4], colourOptions[5]]}
            isMulti
            isSearchable
            options={siteOptions}
          />
        )}

        <FormHelperText mb="5">
          You'll receive up to 10 email alerts per day.
        </FormHelperText>
        <FormHelperText mb="5">
          Unsubscribe any time by clicking the 'unsubscribe' link in any email
          alert.
        </FormHelperText>
        <FormHelperText mb="5">
          We'll automatically unsubscribe you after 1 week. You can return at
          any time to refresh your account and keep receiving alerts.
        </FormHelperText>
        <FormHelperText>
          We'll only send vaccine apppointment alerts and critical account info.
          We will never spam you with marketing junk or share your email address
          with anyone.{" "}
          <Link textDecoration="underline" href="#faq">
            FAQ
          </Link>
        </FormHelperText>

        <Box>
          <Checkbox
            size="lg"
            mt="10px"
            isChecked={hasAcceptedTerms || isEnabled}
            disabled={isEnabled}
            onChange={(e) => setHasAcceptedTerms(e.target.checked)}
          >
            I understand that this is an unofficial site with no warranty and no
            guarantee of accuracy.
          </Checkbox>
        </Box>

        <Flex w="100%" justifyContent="flex-end" mt="20px" flexWrap="wrap">
          {isEnabled ? (
            <>
              {
                <Button
                  onClick={handleSubscribe}
                  variant="solid"
                  colorScheme="gray"
                  mr="5px"
                  mb="5px"
                  isLoading={isLoading}
                  //   variant
                  //   backgroundColor={isSubscribed ? "red.500" : "blue.500"}
                >
                  Refresh subscription for 1 week
                </Button>
              }

              <Button
                onClick={handleSubscribe}
                variant="solid"
                colorScheme="twitter"
                mr="5px"
                mb="5px"
                isLoading={isLoading}
                //   variant
                //   backgroundColor={isSubscribed ? "red.500" : "blue.500"}
              >
                Update settings
              </Button>
              <Button
                onClick={handleUnsubscribe}
                variant="solid"
                colorScheme="red"
                mb="5px"
                isLoading={isLoading}

                //   variant
                //   backgroundColor={isSubscribed ? "red.500" : "blue.500"}
              >
                <IoNotificationsOff
                  size="20px"
                  style={{ marginRight: "2px" }}
                />
                Unsubscribe from alerts
              </Button>
            </>
          ) : (
            <Button
              onClick={handleSubscribe}
              variant="solid"
              mb="5px"
              colorScheme="twitter"
              isLoading={isLoading}
              disabled={!isEnabled && !hasAcceptedTerms}

              //   variant
              //   backgroundColor={isSubscribed ? "red.500" : "blue.500"}
            >
              <IoNotifications size="20px" style={{ marginRight: "2px" }} />
              Subscribe to alerts
            </Button>
          )}
        </Flex>
      </FormControl>
    </Box>
  );
};

export default Preferences;
