import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormHelperText,
  Link,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import SignIn from "./components/SignIn";
import Slots from "./components/Slots";
import { getSlots } from "./utils/api";

export const MOBILE_BREAKPOINT = "480px";
export const TABLET_BREAKPOINT = "768px";
export const DESKTOP_BREAKPOINT = "992px";

function App() {
  const toast = useToast();
  const [slots, setSlots] = useState(null);
  const [dateAdded, setDateAdded] = useState(null);
  const [showLogin, setShowLogin] = useState(null);

  const isDesktopOrLaptop = useMediaQuery(
    `(min-width: ${DESKTOP_BREAKPOINT})`
  )[0];

  const formattedDate = dateAdded && new Date(dateAdded).toString();

  const CONTENT_WIDTH = isDesktopOrLaptop ? "900px" : "calc(100vw - 30px)";

  useEffect(() => {
    getSlots().then((slots) => {
      const firstSiteInfo =
        slots && slots.slots && Object.entries(slots.slots)[0][1];
      const slotsAreInNormalFormat =
        true ||
        (firstSiteInfo && typeof firstSiteInfo.hasAvailability === "boolean");
      if (slotsAreInNormalFormat) {
        setSlots(slots.slots);
        setDateAdded(slots.dateAdded);
      } else {
        toast({
          title: "Error loading",
          description:
            "We're having trouble loading the most recent availability. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    });
  }, []);

  return (
    <>
      <Navbar showLogin={showLogin} setShowLogin={setShowLogin} />
      <Flex w="calc(100vw-20px)" justifyContent="center">
        <Box w={CONTENT_WIDTH} maxW={CONTENT_WIDTH}>
          <Box textAlign="left" pt="20px">
            <Alert status="info">
              <AlertIcon />
              <AlertTitle mr={2}>
                Alerts only help if you're eligible
              </AlertTitle>
              <AlertDescription>
                Get the latest information on the MA vaccine eligibility{" "}
                <Link
                  textDecoration="underline"
                  href="https://www.mass.gov/covid-19-vaccine"
                  target="_blank"
                >
                  here
                </Link>
                .
              </AlertDescription>
            </Alert>
            <Box
              backgroundColor="gray.100"
              p="20px"
              borderRadius="20px"
              my="20px"
              w={CONTENT_WIDTH}
              maxW={CONTENT_WIDTH}
            >
              <SignIn
                slots={slots}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
              />
            </Box>
            <Footer />

            <FAQ />
            <h2
              style={{
                fontSize: "16pt",
                marginBottom: "5pt",
                marginTop: "20px",
              }}
            >
              Current availability
            </h2>
            <p style={{ marginBottom: "20px" }}>
              Alerts are sent every 2 minutes, using the following data pulled
              from{" "}
              <Link
                textDecoration="underline"
                href="http://MACovidVaccines.com"
                target="_blank"
              >
                MACovidVaccines.com
              </Link>
              .<p>Last updated: {formattedDate}</p>
            </p>
            <Slots slots={slots} />
            <Footer />
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default App;
