import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
} from "@chakra-ui/react";
import React from "react";

const FAQ = () => {
  return (
    <Box id="faq">
      <h2 style={{ fontSize: "20pt", marginTop: "50px" }}>FAQ</h2>
      <Accordion w="100%">
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                How does this work?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              VaccineAlerts.org is a free, volunteer created service for getting
              email alerts about new COVID vaccine appointments in
              Massachusetts. Instead of refreshing appointments websites waiting
              for new slots to be added, VaccineAlerts.org monitors for you, and
              alerts you when they show up so you can go claim one yourself.
            </p>
            <br />
            <p>
              When you sign up, you can choose from the list of supported
              vaccination sites. Then you'll receive alerts whenever one of your
              chosen sites adds new appointments. Appointments may disappear
              quickly, so it helps to move fast.
            </p>
            <br />
            <p>
              Appointments are checked every five minutes. Currently, alerts are
              sent whenever one of the sites adds a block of appointments on a
              day that previously had none.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                Can I rely on these alerts to find an appointment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              Absolutely not. Even after signing up, you should continue looking
              for appointments on your own. This service may be able to help you
              by quickly alerting you to new appointment openings that you might
              otherwise miss, but it comes with no guarantees.
            </p>
            <br />
            <p>
              These alerts may be turned off at any point if we can no longer
              access the appointment data or if I no longer afford to pay for
              the emails.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                Do I need this?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              You might not. If you're currently eligible for the vaccine and
              are able to travel to one of the vaccination sites in the state
              that has availability, you can probably book an appointment right
              now. As of writing this there is plenty of availability over the
              next few week, especially at major sites like Gillette Stadium and
              Fenway Park.
            </p>
            <br />
            <p>
              However, if you're not able to make it to one of the sites with
              availability, VaccineAlerts may be able to help you grab an
              appointment at a site near you–no gaurantees.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                Where does the data come from?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              The data is pulled every five minutes from{" "}
              <Link
                textDecoration="underline"
                href="http://MACovidVaccines.com"
                target="_blank"
              >
                MACovidVaccines.com
              </Link>
              , a site which compiles MA appointment availability by scraping
              data from several different sources.
            </p>
            <br />
            <p>
              Because of the way the data is collected, it may not always be
              100% accurate, and things could break over time, but I'll try my
              best to keep up with any changes.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                What vaccination sites do you support?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              Currently, we can monitor all the vaccination sites listed on{" "}
              <Link
                textDecoration="underline"
                href="http://MACovidVaccines.com"
                target="_blank"
              >
                MACovidVaccines.com
              </Link>
            </p>
            <p>
              This does not cover all the sites in the state, so you should
              continue looking for other sites on your own as well.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                How many alerts will I get?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              You will get a maximum of 10 alerts per day, for as long as you
              are subscribed.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                How long will I get alerts for?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              You will get alerts for 7 days after signing up, and then you will
              be automatically unsubscribed.
            </p>
            <p>
              If you would like more alerts, simply log back in and click the
              refresh subscription button to get 7 more days of alerts.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                How do I unsubscribe?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              You'll be automatically unsubscribed after 7 days, but if you
              would like to unsubsribe earlier:
            </p>
            <p>Click the unsubscribe link in the bottom of any email link.</p>
            <p>
              Or log into this website and click the big red unsubscribe button.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                How do I know if I'm eligible for the vaccine?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              We're not affiliated with any government or healthcare entities,
              so we can't help with this directly. You can get the latest info
              on the MA vaccine rollout from{" "}
              <Link
                textDecoration="underline"
                href="https://www.mass.gov/covid-19-vaccine"
                target="_blank"
              >
                Mass.gov
              </Link>
              .
            </p>

            <p></p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                Who built this?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <p>
              This site was built by{" "}
              <Link
                textDecoration="underline"
                target="_blank"
                href="https://www.linkedin.com/in/feehrer/"
              >
                Derek Feehrer
              </Link>
              . I'm a Boston resident and software engineer, and I am thoroughly
              fed up with this pandemic.
            </p>
            <br />

            <p>
              I built this site because friends and family members were having a
              lot of trouble finding vaccine appointments in MA and having to
              refresh the scheduler pages all day to wait for appointments to
              pop up.
            </p>
            <br />
            <p>
              This is just an experiment, but I hope this site helps even a few
              more people get vaccinated by 1. making the process less painful
              and 2. filling appointment slots that would otherwise go to waste
              due to cancellations. I also hope it proves that government
              sofware doesn't have to be terrible.
            </p>
            <br />
            <p>
              The data comes from Olivia Adams' awesome website,{" "}
              <Link
                textDecoration="underline"
                href="http://MACovidVaccines.com"
                target="_blank"
              >
                MACovidVaccines.com
              </Link>
              , which scrapes and compiles appointment data from several
              different sources. VaccineAlerts.org's alerting system would not
              be possible without it.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={600} fontSize="14pt">
                How can I get in touch?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Shoot me an email{" "}
            <Link
              textDecoration="underline"
              href="mailto:vaccinealerts.org@gmail.com"
            >
              vaccinealerts.org@gmail.com
            </Link>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FAQ;
