import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";
import Routes from "./components/Routes";
import "./utils/firebaseSetup";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import "focus-visible/dist/focus-visible";

import { Global, css } from "@emotion/react";
const GlobalStyles = css`
  html,
  body {
    height: 100vh;
  }
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  /* div {
    outline: none;
    box-shadow: none;
  } */
`;
const theme = extendTheme({
  components: { Button: { baseStyle: { _focus: { boxShadow: "none" } } } },
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <ChakraProvider theme={theme}>
      <Routes />
    </ChakraProvider>,
    rootElement
  );
} else {
  render(
    <ChakraProvider theme={theme}>
      <Routes />
    </ChakraProvider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
