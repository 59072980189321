import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  List,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { IoArrowForward, IoCheckmarkCircle } from "react-icons/io5";
import SignUpButton from "./SignUpButton";

const Slots = ({ slots }) => {
  if (slots) {
    return (
      <Accordion w="100%">
        {Object.entries(slots)
          .sort((a, b) => {
            const aAvailabilityCount =
              (a[1].availability &&
                a[1].hasAvailability &&
                Object.entries(a[1].availability).reduce(
                  (count, [date, dateInfo]) => {
                    return count + dateInfo.numberAvailableAppointments;
                  },
                  0
                )) ||
              0;
            const bAvailabilityCount =
              (b[1].availability &&
                b[1].hasAvailability &&
                Object.entries(b[1].availability).reduce(
                  (count, [date, dateInfo]) => {
                    return count + dateInfo.numberAvailableAppointments;
                  },
                  0
                )) ||
              0;
            if (aAvailabilityCount > bAvailabilityCount) {
              return -1;
            } else {
              return 1;
            }
          })
          .map(([siteName, siteInfo]) => {
            return (
              <AccordionItem key={siteName} paddingY="10px">
                <h2>
                  <Box w="100%">
                    <Box textAlign="left">
                      <Flex textAlign="left" w="100%">
                        <Box>
                          {siteInfo.hasAvailability && siteInfo.availability ? (
                            <IoCheckmarkCircle
                              size="30px"
                              color="green"
                              style={{ marginTop: "-2px", marginRight: "5px" }}
                            />
                          ) : null}
                        </Box>
                        <strong style={{ fontSize: "13pt" }}>{siteName}</strong>
                      </Flex>
                      <p
                        style={{
                          fontSize: "11pt",
                          paddingLeft:
                            siteInfo.hasAvailability && siteInfo.availability
                              ? "35px"
                              : 0,
                        }}
                      >
                        {siteInfo.city}
                      </p>

                      <List
                        w="100%"
                        textAlign="left"
                        margin="10px"
                        paddingLeft={
                          siteInfo.hasAvailability && siteInfo.availability
                            ? "20px"
                            : 0
                        }
                      >
                        {siteInfo.hasAvailability &&
                          siteInfo.availability &&
                          Object.entries(siteInfo.availability)
                            .sort(([date1], [date2]) => {
                              if (date1 > date2) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            .filter(([date, info]) => info.hasAvailability)
                            .map(([date, info]) => (
                              <Flex
                                w="100%"
                                p="5px"
                                justifyContent="space-between"
                                alignItems="center"
                                _hover={{ backgroundColor: "#E5F4FD" }}
                              >
                                <p style={{ padding: 0, margin: 0 }}>
                                  {date} :{" "}
                                  <strong>
                                    {info.numberAvailableAppointments}{" "}
                                    appointments
                                  </strong>
                                </p>
                                <p style={{ padding: 0, margin: 0 }}>
                                  {siteInfo.availability[date].signUpLink ? (
                                    <a
                                      href={
                                        siteInfo.availability[date].signUpLink
                                      }
                                    >
                                      <Button
                                        variant="ghost"
                                        colorScheme="twitter"
                                      >
                                        Sign up
                                        <IoArrowForward size="20px" />
                                      </Button>
                                    </a>
                                  ) : (
                                    <p>No sign up link available</p>
                                  )}
                                </p>
                              </Flex>
                            ))}
                      </List>
                    </Box>
                    {/* <Flex alignItems="center">
                      <SignUpButton siteInfo={siteInfo} /> 
                      <AccordionIcon />
                    </Flex> */}
                  </Box>
                  <AccordionButton textDecoration="underline">
                    More info
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} textAlign="left">
                  {siteInfo.street && siteInfo.city && siteInfo.zip && (
                    <p>
                      <strong>Address: </strong>
                      {siteInfo.street +
                        ", " +
                        siteInfo.city +
                        " MA " +
                        siteInfo.zip}
                    </p>
                  )}
                  {siteInfo.extraData &&
                    typeof siteInfo.extraData === "object" &&
                    Object.entries(siteInfo.extraData).map(([type, data]) => (
                      <p key={type}>
                        <strong>{type}: </strong>
                        {data}
                      </p>
                    ))}
                  {siteInfo.extraData &&
                    typeof siteInfo.extraData === "string" && (
                      <p>{siteInfo.extraData}</p>
                    )}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    );
  } else {
    return (
      <Flex w="100%" height="300px" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }
};

export default Slots;
