import { Box, Link } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Box padding="20px" fontSize="12px" textAlign="center">
      <p>Volunteer-created service.</p>
      <p>Not affiliated with or endorsed by any government entities.</p>
      <p>
        This site is provided with absolutely no warranty and no guarantee of
        accuracy.
      </p>
      <p>
        It is your responsibility to verify appointment availability and
        eligibility yourself.
      </p>
      <Link href="/terms" mr="5px">
        Terms of service
      </Link>
      <Link href="/privacy">Privacy policy</Link>
    </Box>
  );
};

export default Footer;
