import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormHelperText,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import React, { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useHistory } from "react-router-dom";
require("firebase/auth");

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,

    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      requireDisplayName: false,
      // emailLinkSignIn: () => {
      //   firebase
      //     .auth()
      //     .sendSignInLinkToEmail(email, actionCodeSettings)
      //     .then(() => {
      //       // The link was successfully sent. Inform the user.
      //       // Save the email locally so you don't need to ask the user for it again
      //       // if they open the link on the same device.
      //       window.localStorage.setItem("emailForSignIn", email);
      //       // ...
      //     })
      //     .catch((error) => {
      //       var errorCode = error.code;
      //       var errorMessage = error.message;
      //       // ...
      //     });
      // },
    },
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const SignInModal = ({ onClose }) => {
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(
    firebase.auth().currentUser
  );
  const [userInfo, setUserInfo] = useState(null);

  firebase.auth().onAuthStateChanged(function (user) {
    if (user && !isAuthenticated) {
      setIsAuthenticated(true);
      history.push("/");
      //   getUserInfo()
      //     .then((res) => res.json())
      //     .then((data) => {
      //       setUserInfo(data);
      //     });
    } else {
      // No user is signed in.
    }
  });

  if (!isAuthenticated) {
    return (
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign in</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ textAlign: "center" }}>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
              <FormHelperText>
                By proceeding, you agree to our <a href="#">terms of service</a>{" "}
                and <a href="#">privacy policy.</a>
              </FormHelperText>
            </div>
          </ModalBody>

          {/* <ModalFooter>

          </ModalFooter> */}
        </ModalContent>
      </Modal>
    );
  }
  return <div></div>;
};

export default SignInModal;
