import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const isQA = false;

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = isQA
  ? {}
  : {
      apiKey: "AIzaSyDEj07nePFxfwLQ7Sl_EuoRq1Mlwle695A",
      authDomain: "vaccine-alerts.firebaseapp.com",
      projectId: "vaccine-alerts",
      storageBucket: "vaccine-alerts.appspot.com",
      messagingSenderId: "885549550596",
      appId: "1:885549550596:web:4264d86726d95f4e0e7951",
    };

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    localStorage.setItem("PREVIOUSLY_SIGNED_IN", true);
  } else {
    localStorage.removeItem("PREVIOUSLY_SIGNED_IN");
  }
});

export default {};
