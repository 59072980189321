import React, { useState, Fragment } from "react";
import {
  Box,
  Flex,
  Text,
  Icon,
  Link,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  Button,
  Avatar,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
// import { LIGHT_GRAY } from "./constants/chakraTheme";
import firebase from "firebase/app";
import SignInModal from "./SignInModal";
import { IoMenu } from "react-icons/io5";
require("firebase/auth");
export const DESKTOP_BREAKPOINT = "992px";

const handleSignOutClick = () => {
  firebase.auth().signOut();
  window.location.reload();
};

const NavButton = ({ children, textColor, ...props }) => (
  <Link px={2} color={textColor} paddingBottom={0} marginBottom={0} {...props}>
    {children}
  </Link>
);
const NavLink = ({ children, textColor, ...props }) => (
  <Link px={2} color={textColor} {...props} as={RouterLink}>
    {children}
  </Link>
);

function Navbar({ setShowLogin, showLogin }) {
  const { toggleColorMode, colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const textColor = isDarkMode ? "white" : "black";
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("PREVIOUSLY_SIGNED_IN") || firebase.auth().currentUser
  );
  const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
  const isDesktopOrLaptop = useMediaQuery(
    `(min-width: ${DESKTOP_BREAKPOINT})`
  )[0];

  const handleSignInClick = () => {
    setIsSignInModalVisible(true);
  };
  const handleSignInModalClose = () => {
    setIsSignInModalVisible(false);
  };

  firebase.auth().onAuthStateChanged(function (user) {
    if (user && !isAuthenticated) {
      setIsAuthenticated(true);
    }
  });

  const NavLink = ({ children, ...props }) => (
    <Link px={2} color={textColor} {...props} as={RouterLink}>
      {children}
    </Link>
  );

  return (
    <Fragment>
      <Flex
        bg={isDarkMode ? "black" : "white"}
        borderBottom={
          isDarkMode ? `1px rgba(255,255,255,0.2) solid` : `1px lightgray solid`
        }
        w="100%"
        px={5}
        pl={2}
        py={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <NavButton pl={3} color={textColor} href="/">
            <img src="/logo.svg" width="230px" />
          </NavButton>
        </Flex>
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          {/* <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About </NavLink> */}

          {isDesktopOrLaptop ? (
            <>
              {" "}
              <NavButton href="/about">About</NavButton>
              <NavButton href="https://www.buymeacoffee.com/derekfeehrer">
                Contribute
              </NavButton>
              {isAuthenticated ? (
                <NavButton onClick={handleSignOutClick}>Sign out</NavButton>
              ) : (
                <NavButton onClick={() => setShowLogin(!showLogin)}>
                  {showLogin ? "Sign up" : "Sign in"}
                </NavButton>
              )}
            </>
          ) : (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<IoMenu />}
                fontSize="20pt"
                variant="ghost"
              />
              <MenuList>
                <MenuItem href="/about" as={Link}>
                  About
                </MenuItem>
                <MenuItem
                  as={Link}
                  href="https://www.buymeacoffee.com/derekfeehrer"
                >
                  Contribute
                </MenuItem>
                {isAuthenticated ? (
                  <MenuItem onClick={handleSignOutClick}>Sign out</MenuItem>
                ) : (
                  <MenuItem onClick={() => setShowLogin(!showLogin)}>
                    {showLogin ? "Sign up" : "Sign in"}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}

          {/* <NavLink to="/contact">Contact</NavLink> */}
        </Flex>
      </Flex>
    </Fragment>
  );
}

export default Navbar;
