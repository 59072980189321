import { Box, Flex, Image, Link } from "@chakra-ui/react";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

const About = () => {
  return (
    <>
      <Navbar />
      <Flex w="100vw" justifyContent="center" alignItems="center">
        <Box padding="20px" maxW="500px">
          <Flex w="100%" justifyContent="center" mY="10px">
            <Image src="/pro-pic.png" w="100px" h="100px" />
          </Flex>
          <Box w="100%" textAlign="left"></Box>
          <p>Hey everyone,</p> <br />
          <p>
            My name is{" "}
            <Link
              textDecoration="underline"
              target="_blank"
              href="https://www.linkedin.com/in/feehrer/"
            >
              Derek
            </Link>
            , and I'm a software engineer in Boston.
          </p>
          <br />
          <p>
            I built this tool after hearing from countless friends and family
            members about how difficult it is to find vaccine appointments in
            MA. The state appointment websites are incredibly hard to navigate,
            and appointments come and go.
          </p>
          <br />
          <p>
            VaccineAlerts is designed to help people find vaccine appointments
            even if they can't constantly refresh the state websites waiting for
            new appointments to pop up.
          </p>
          <br />
          <p>
            This tool relies on the amazing work done by Olivia Adams at{" "}
            <Link
              textDecoration="underline"
              href="http://MACovidVaccines.com"
              target="_blank"
            >
              MACovidVaccines.com
            </Link>
            , which scrapes data from several different MA appointment websites
            every minute. The data is then pulled into VaccineAlerts.org every 2
            minutes and email alerts are sent out immediately to all
            subscribers.
          </p>
          <br />
          <p>
            I'll keep the site up and sending alerts for as long as I can afford
            to.
          </p>
          <br />
          <p>
            Lastly, please remember to double check eligibility and only get
            vaccinated when it's your turn.
          </p>
          <br />
          <p>Thanks! Let's kick this virus.</p>
          <br />
          <p>-Derek</p>
          <br />
          <p>
            Contact:{" "}
            <Link
              textDecoration="underline"
              href="mailto:vaccinealerts.org@gmail.com"
            >
              vaccinealerts.org@gmail.com
            </Link>
          </p>
        </Box>
      </Flex>
      <Footer />
    </>
  );
};

export default About;
