const LOCAL_PORT = window.location.origin.includes(5001) ? 5001 : 3000;
const API_PORT = 5001;

export const isLocal = () => window.location.host.includes("localhost");

const isDeployedQa = () =>
  window.location.host.includes("qa-vaccine-alerts") ||
  window.location.host.includes("qa.vaccinealerts");

const shouldUseLocalProd = () => true; // localStorage.getItem("LOCAL_PROD");

export const isQA = isDeployedQa() || (isLocal() && !shouldUseLocalProd());

export const getPublicApiDomain = () => {
  if (isLocal()) {
    if (shouldUseLocalProd()) {
      return `http://localhost:${API_PORT}/vaccine-alerts/us-central1/public`;
    } else {
      return `http://localhost:${API_PORT}/qa-vaccine-alerts/us-central1/public`;
    }
  } else {
    if (isDeployedQa()) {
      return "https://us-central1-qa-vaccine-alerts.cloudfunctions.net/public";
    } else {
      return "https://us-central1-vaccine-alerts.cloudfunctions.net/public";
    }
  }
};

export const getApiDomain = () => {
  if (isLocal()) {
    if (shouldUseLocalProd()) {
      return `http://localhost:${API_PORT}/vaccine-alerts/us-central1/app`;
    } else {
      return `http://localhost:${API_PORT}/qa-vaccine-alerts/us-central1/app`;
    }
  } else {
    if (isDeployedQa()) {
      return "https://us-central1-qa-vaccine-alerts.cloudfunctions.net/app";
    } else {
      return "https://us-central1-vaccine-alerts.cloudfunctions.net/app";
    }
  }
};

export const getPasswordLessSignInLink = () => {
  if (isLocal()) {
    return `http://localhost:${LOCAL_PORT}`;
  } else {
    if (isDeployedQa()) {
      return "https://qa.vaccinealerts.org";
    } else {
      return "https://vaccinealerts.org";
    }
  }
};
